"use client"

import { Avatar } from "./avatar"
import UserProfileDialog from "@/components/shared/UserProfileDialog"
import { User } from "@/graphql/generated/apollo/graphql"
import { cn } from "@/utils/cn"
import clsx from "clsx"
import { EthformatAddress } from "./ethaddress"
import Link from "next/link"
import { PartialPick } from "@/utils/types"
import makeBlockie from "ethereum-blockies-base64"
import { isAddress } from "viem"

interface AvatarProfileProps {
  user?: PartialPick<
    User,
    "avatar" | "username" | "fullname" | "ethereum_address"
  > | null /* this is ALL we care about, just need it to partial match those and we are good */
  showDialog?: boolean
  showName?: boolean
  widthLimit?: boolean
  size?: "sm" | "md" | "lg"
  desktopSize?: "sm" | "md" | "lg"
  hideAvatar?: boolean
  hideUsername?: boolean
  className?: string
  textClassName?: string
  address?: string
}

export const AvatarProfile = ({
  user,
  showDialog = true,
  showName = false,
  hideAvatar = false,
  hideUsername = false,
  widthLimit = true,
  size = "md",
  desktopSize,
  className,
  textClassName,
  address,
}: AvatarProfileProps): JSX.Element => {
  const sizes = {
    sm: "size-7",
    md: "size-8",
    lg: "size-10",
  }
  const userAddress = address ? EthformatAddress(address) : "unknown"
  const checkAddress = isAddress(user?.ethereum_address || "")
    ? user?.ethereum_address
    : isAddress(`0x${user?.ethereum_address}` || "")
      ? `0x${user?.ethereum_address}`
      : userAddress
  const basic = (
    <div
      className={clsx(
        "flex items-center jusitfy-start group gap-x-2 overflow-hidden",
        className,
        {
          "first:inline": hideAvatar,
        }
      )}
    >
      {hideAvatar ? null : user?.username ? (
        <Link href={`/${user?.username}`}>
          <Avatar
            src={user?.avatar || makeBlockie(checkAddress || "0x")}
            alt={user?.username || EthformatAddress(checkAddress || "0x")}
            className={sizes[size]}
          />
        </Link>
      ) : (
        <Avatar
          src={user?.avatar || makeBlockie(checkAddress || "0x")}
          alt={user?.username || EthformatAddress(checkAddress || "0x")}
          className={sizes[size]}
        />
      )}
      <div
        className={cn("flex flex-col text-left", {
          "leading-3": size === "md",
          inline: hideAvatar,
        })}
      >
        {showName && size != "sm" && (
          <div
            className={cn(
              `
                text-base
                group-hover/avatar-profile:underline
              `,
              {
                "text-sm": size === "md",
                inline: hideAvatar,
              }
            )}
          >
            {user?.fullname}
          </div>
        )}
        {!hideUsername && (
          <div
            className={cn(
              `
                text-base text-black
                dark:text-white
              `,
              {
                "w-24": widthLimit,
                "!overflow-visible": !widthLimit,
                "text-[10px]": showName && size === "md",
                "text-[11px]": showName && size === "lg",
                "text-typography-grey-1": showName === true,
                "text-sm": size === "sm",
                "group-hover/avatar-profile:underline": showName === false,
                "text-typography-grey-2": !user?.username,
                "md:text-base": desktopSize === "md",
                inline: hideAvatar,
              }
            )}
          >
            {user?.username ? (
              <Link
                className={cn("hover:underline", textClassName)}
                href={`/${user?.username}`}
              >
                {user?.username}
              </Link>
            ) : (
              userAddress
            )}
          </div>
        )}
      </div>
    </div>
  )

  let content = basic
  if (showDialog) {
    content = (
      <UserProfileDialog
        side="top"
        ethAddress={checkAddress}
        hideAvatar={hideAvatar}
      >
        {basic}
      </UserProfileDialog>
    )
  }

  return content
}

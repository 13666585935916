import { useProfileStore } from "@/web/stores/profile"
import { Currency, getCurrencyByAddress } from "@/utils/currency"
import { useEffect, useMemo, useState } from "react"
import { useAccount } from "wagmi"
import {
  ALLOWANCE_COPY,
  CurrencyDisplay,
  DataRow,
  FixedMobileButton,
  Terms,
  balanceMessage,
  symbolSwap,
} from "./SharedElements"
import { CurrencyBalanceFormat } from "../connectButton/connectButton"
import { Loader } from "@/ui/loader"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import HR from "@/ui/hr"
import { useBuy } from "@/web/hooks/useBuy"
import {
  checkIsV1Token,
  getDefaultMarketplaceTokenAbi,
} from "@/utils/getTokenAbi"
import { useModal } from "@/web/stores/infoModal"
import { formatUnits, getAddress, zeroAddress } from "viem"
import { useCurrencyBalances } from "@/web/hooks/useCurrencyBalances"
import { readContract } from "viem/actions"
import { getChainClients } from "@/utils/chainClients"

export const BuyContent = ({
  token,
  closeModal,
  approvedTerms,
  setApprovedTerms,
}: {
  token: Erc721Token
  closeModal: () => void
  approvedTerms: boolean
  setApprovedTerms: (approvedTerms: boolean) => void
}): JSX.Element => {
  const { address, chainId } = useAccount()
  const { setType, setIsOpen, setAction } = useModal()
  const { profile } = useProfileStore()
  const [contractWeiPrice, setContractWeiPrice] = useState(0n)

  const priceCurrency = useMemo(() => {
    const currency =
      token?.current_sale_price?.[0]?.currency_address?.address || zeroAddress
    return getCurrencyByAddress(currency)
  }, [token])

  const isV1Token = checkIsV1Token(token.contract_address, chainId || 1)
  const marketAbi = useMemo(() => {
    return getDefaultMarketplaceTokenAbi(token.contract_address, chainId || 1)
  }, [token, chainId])

  const getContractWeiPrice = async () => {
    const client = getChainClients(chainId || 1)
    if (marketAbi._type === "SuperRareV1") {
      const data = await readContract(client.viem, {
        address: marketAbi.contract,
        abi: marketAbi.abi,
        functionName: "salePriceOfToken",
        args: [BigInt(token.token_id)],
      })
      return setContractWeiPrice(data)
    }
    const data = await readContract(client.viem, {
      address: marketAbi.contract,
      abi: marketAbi.abi,
      functionName: "tokenSalePrices",
      args: [
        getAddress(token.contract_address),
        BigInt(token.token_id),
        zeroAddress,
      ],
    })
    return setContractWeiPrice(data?.[2])
  }

  useEffect(() => {
    getContractWeiPrice()

    return () => {
      setContractWeiPrice(0n)
    }
  }, [token.token_id])

  // const contractWeiPrice = data?.[2] || BigInt(0)
  const { buyToken, isLoading, enoughAllowance } = useBuy({
    token: token,
    currencyAddress: priceCurrency.address as `0x${string}`,
    weiPrice: contractWeiPrice,
  })
  const { balance, hasEnoughBalance } = useCurrencyBalances({
    amountWei: contractWeiPrice,
    selectedCurrency: priceCurrency,
  })
  const onBuy = () => {
    closeModal()
    if (!profile?.username) {
      setType("complete-profile")
      setAction("new-art")
      setIsOpen(true)
    }
  }
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div>
        <p className="pb-1 text-sm uppercase tracking-wide text-typography-grey-1">
          List price
        </p>
        <h4>
          {
            <CurrencyDisplay
              amount={formatUnits(contractWeiPrice, priceCurrency.decimals)}
              currency={priceCurrency}
            />
          }
        </h4>
        <div className="mt-6 gap-1">
          <DataRow label="Your current balance">
            <CurrencyBalanceFormat
              className="text-sm uppercase text-sr-text-primary"
              balance={balance}
              decimals={3}
              isFormatted
              symbol={symbolSwap(priceCurrency.symbol)}
              symbolClass="text-sm"
            />
          </DataRow>

          <DataRow
            label={`Total amount ${!isV1Token ? `(+3%)` : ``}:`}
            gray
            error={
              !address || hasEnoughBalance
                ? undefined
                : balanceMessage(priceCurrency?.symbol)
            }
          >
            <CurrencyDisplay
              amount={formatUnits(contractWeiPrice, priceCurrency.decimals)}
              currency={priceCurrency as Currency}
              withFee={!isV1Token}
            />
          </DataRow>
        </div>
      </div>
      <div className="mt-10">
        <HR />
        {!checkIsV1Token(token.contract_address, chainId || 1) ? (
          <p className="my-3 text-sm text-sr-text-secondary">
            Final price includes a 3% buyers fee to the SuperRare DAO Treasury.{" "}
            <a
              className="underline"
              href="https://help.superrare.com/en/articles/5662523-what-is-the-fee-structure-on-superrare"
            >
              Learn more
            </a>
          </p>
        ) : null}
        {!profile?.username && (
          <Terms
            approvedTerms={approvedTerms}
            setApprovedTerms={setApprovedTerms}
          />
        )}
        <FixedMobileButton
          onClick={() => buyToken(onBuy)}
          disabled={
            !!profile?.username &&
            !approvedTerms &&
            (isLoading || !hasEnoughBalance)
          }
        >
          {isLoading ? (
            <Loader kind="simple" />
          ) : !enoughAllowance ? (
            ALLOWANCE_COPY
          ) : (
            "BUY NOW"
          )}
        </FixedMobileButton>
      </div>
    </div>
  )
}

import { currentNetwork } from "@/constants/network"
import { useRarePriceUpdater } from "@/web/hooks/useRarePriceUpdater"
import { useUsdcPriceUpdate } from "@/web/hooks/useUsdcPriceUpdate"
import { useProfileStore } from "@/web/stores/profile"
import { trpc } from "@/web/clients/trpc"
import { Button } from "@/ui/button"
import { Dialog } from "@/ui/dialog"
import { cn } from "@/utils/cn"
import { parseViemError } from "@/utils/errors"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import { MouseEventHandler, useEffect, useMemo } from "react"
import toast from "react-hot-toast"
import { useToggle } from "react-use"
import { useAccount, useSwitchChain } from "wagmi"

export const ConnectWalletButton = ({
  onClick,
  wrapperClassName,
  btnClassName,
}: {
  onClick?: MouseEventHandler<HTMLDivElement>
  wrapperClassName?: string
  btnClassName?: string
}): JSX.Element => {
  const { profile, setProfile } = useProfileStore()
  const { address, chain } = useAccount()
  const { error, isSuccess, switchChain } = useSwitchChain()
  const [wrongChain, toggleWrongChain] = useToggle(false)

  useEffect(() => {
    if (address) toggleWrongChain(chain?.id !== currentNetwork.id)
    if (isSuccess) toast.success("Switched!")
    if (error) toast.error(parseViemError(error.message))
  }, [address, chain, error])

  const { formatBalance: rareBalance } = useRarePriceUpdater(address)
  const { formatBalance: usdcBalance } = useUsdcPriceUpdate(address || "")
  useMemo(() => {
    setProfile({ ...profile, rareBalance, usdcBalance })
  }, [rareBalance])

  const getProfile = trpc.profile.getByAddresses.useQuery(
    {
      addresses: address ? [address.toLowerCase()] : [],
    },
    {
      enabled: false,
    }
  )

  useEffect(() => {
    if (address) getProfile.refetch()
  }, [address])

  useEffect(() => {
    const user = getProfile.data?.users.users_by_ethereum_addresses[0]
    setProfile(user || {})
  }, [getProfile.data])

  const handleKeyDown = (event: any): void => {
    if (event.key === "Enter" || event.key === "Space") {
      onClick?.(event)
    }
  }

  return (
    <>
      <Dialog open={wrongChain}>
        <Dialog.Content title="Wrong Network" disableClose>
          <div className="">
            {chain && (
              <div className="mt-2 pb-6">
                Switch to {currentNetwork.name} to connect to SuperRare.
              </div>
            )}

            <Button
              uppercase
              className="w-full"
              disabled={!switchChain || currentNetwork.id === chain?.id}
              key={currentNetwork.id}
              onClick={() => switchChain?.({ chainId: currentNetwork.id })}
            >
              Switch to {currentNetwork.name}
            </Button>
          </div>
        </Dialog.Content>
      </Dialog>
      <div
        className={cn(wrapperClassName)}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
        aria-pressed="false"
      >
        <ConnectButton.Custom>
          {({
            account,
            chain,
            openChainModal,
            openConnectModal,
            authenticationStatus,
            mounted,
          }) => {
            const ready = mounted && authenticationStatus !== "loading"
            const connected =
              ready &&
              account &&
              chain &&
              (!authenticationStatus ||
                authenticationStatus === "authenticated")

            return (
              <div
                {...(!ready && {
                  "aria-hidden": true,
                  style: {
                    opacity: 0,
                    pointerEvents: "none",
                    userSelect: "none",
                  },
                })}
              >
                {(() => {
                  if (!connected) {
                    return (
                      <div
                        className={`
                          px-4 pt-3
                          lg:px-0 lg:pt-0
                        `}
                      >
                        <Button
                          className={cn(btnClassName)}
                          onClick={openConnectModal}
                          type="button"
                          uppercase
                          kind="primary"
                          size="lg"
                        >
                          Connect
                        </Button>
                      </div>
                    )
                  }
                  if (chain.unsupported) {
                    return (
                      <button onClick={openChainModal} type="button">
                        Wrong network
                      </button>
                    )
                  }
                  return null
                })()}
              </div>
            )
          }}
        </ConnectButton.Custom>
      </div>
    </>
  )
}

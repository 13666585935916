import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { genToastId } from "@/utils/toast"
import toast from "react-hot-toast"
import { useAccount, useWriteContract } from "wagmi"
import { Address, formatUnits, getAddress, zeroAddress } from "viem"
import { getTxValue } from "@/utils/priceUtils"
import { TxToastError } from "@/utils/txUtils"
import { useSetMaxAllowance } from "./useSetMaxAllowance"
import {
  checkIsV1Token,
  getDefaultMarketplaceTokenAbi,
} from "@/utils/getTokenAbi"
import { useTransactionModalStore } from "@/web/stores/transactionModal"
import { getSuperRareBazaar } from "@/contracts/superrarebazaar"
import { getCurrencyByAddress } from "@/utils/currency"
type BidToken = Pick<
  Erc721Token,
  "contract_address" | "current_sale_price" | "token_id" | "erc721_owner"
>

export const useMakeOffer = ({
  token,
  weiPrice,
  currencyAddress,
}: {
  token: BidToken
  marketAddress?: string
  weiPrice: bigint
  currencyAddress: Address
  chainId?: number
}): {
  makeOffer: (onSubmit?: () => void) => Promise<void>
  isLoading: boolean
  enoughAllowance: boolean
} => {
  const { address, chain: walletChain } = useAccount()
  const chainId = walletChain?.id || 1
  const currency = currencyAddress ?? zeroAddress
  const isEth = currency === zeroAddress
  const isV1Token = checkIsV1Token(
    token.contract_address,
    chainId || walletChain?.id || 1
  )
  const { setHash, setToastId } = useTransactionModalStore()

  const tokenContractAddress = getAddress(token.contract_address)
  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()
  const bazaarAddress = getSuperRareBazaar(chainId || 1)
  const { allowance, isLoadingAllowance, setMaxAllowance } = useSetMaxAllowance(
    bazaarAddress,
    currency,
    !!address &&
      !isEth &&
      getDefaultMarketplaceTokenAbi(tokenContractAddress, chainId || 1)
        ._type !== "SuperRareV1"
  )
  const currencyObject = getCurrencyByAddress(currencyAddress)
  const parsedAllowance = allowance
    ? Number(formatUnits(allowance, currencyObject?.decimals))
    : 0
  const parsedWeiPrice = Number(
    formatUnits(BigInt(weiPrice), currencyObject?.decimals)
  )
  const makeOffer = async (onSubmit?: () => void): Promise<void> => {
    const randomId = genToastId()
    if (!writeContractAsync) {
      toast.error("Error: writeAsync " + errorContract)
      return
    }
    const value = isEth ? getTxValue(weiPrice) : undefined
    try {
      if (
        (!parsedAllowance || parsedAllowance < parsedWeiPrice) &&
        !isEth &&
        !isV1Token
      ) {
        await setMaxAllowance(randomId)
        return
      }

      toast.loading("Awaiting user confirmation...", { id: randomId })
      const runTx = async () => {
        const abiDetails = getDefaultMarketplaceTokenAbi(
          tokenContractAddress,
          chainId || walletChain?.id || 1
        )
        switch (abiDetails._type) {
          case "SuperRareV1": {
            const result = await writeContractAsync({
              address: abiDetails?.contract,
              abi: abiDetails?.abi,
              functionName: "bid",
              args: [BigInt(token.token_id)],
              value: BigInt(weiPrice),
            })
            return result
          }
          case "Bazaar": {
            const result = await writeContractAsync({
              address: abiDetails?.contract,
              abi: abiDetails?.abi,
              functionName: "offer",
              chainId: chainId || walletChain?.id || 1,
              args: [
                tokenContractAddress,
                BigInt(token.token_id),
                currencyAddress as `0x${string}`,
                BigInt(weiPrice),
                false,
              ],
              value,
            })
            return result
          }
        }
      }
      const result = await runTx()
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return {
    makeOffer,
    isLoading: isPending || (isLoadingAllowance && !isEth),
    enoughAllowance:
      isEth ||
      isV1Token ||
      (!!parsedAllowance && parsedAllowance >= parsedWeiPrice),
  }
}

"use client"

import { useThemeStore } from "@/web/stores/theme"
import { Share2Icon } from "@radix-ui/react-icons"
import { useEffect, useState } from "react"

const ServiceWorker = () => {
  const { mode } = useThemeStore()
  const [isIOS, setIsIOS] = useState(false)
  const [isStandalone, setIsStandalone] = useState(false)

  useEffect(() => {
    const themeColor =
      mode == "dark" ? "#000" : mode == "dim" ? "#212121" : "#fff"

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/service-worker.js").then(() => {
        document
          .querySelector("meta[name=theme-color]")
          ?.setAttribute("content", themeColor)
      })
      //.catch((error) => {})
    }
  }, [])

  useEffect(() => {
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
    const standalone = window.matchMedia("(display-mode: standalone)").matches

    setIsIOS(iOS)
    setIsStandalone(standalone)
  }, [])

  return null

  return (
    <>
      {isIOS && !isStandalone && (
        <div
          className={`
            fixed bottom-0 left-0 right-0 z-[1000] flex w-full gap-3 bg-sr-700 p-4 text-center text-white
            dark:bg-white dark:text-sr-700
          `}
        >
          <img
            src="/assets/pwa/manifest-icon-192.maskable.png"
            className="h-12 w-12 rounded-lg border border-sr-border-primary"
            alt=""
          />
          <div className="flex-1 text-right">
            <p>Add SuperRare to your Home Screen</p>
            <p className="text-base">
              Tap <Share2Icon className="inline text-blue-500" /> then Add to
              Home Screen
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default ServiceWorker

import { useAccount, useWriteContract } from "wagmi"
import RareToken from "@/abis/RareToken.json"
import { useRareAllowance } from "./useRareAllowance"
import { convertRarestTokenToDecimals } from "@/utils/rarestToken"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { TxToastError } from "@/utils/txUtils"
import useWaitForTxConfirmation from "./useWaitForTxConfirmation"
import { genToastId } from "@/utils/toast"

export const useSetMaxAllowance = (
  spenderAddress: `0x${string}`,
  currencyAddress: `0x${string}`,
  enabled: boolean
): {
  isLoadingAllowance: boolean
  setMaxAllowance: (toastId: string) => Promise<void>
  allowance: bigint
} => {
  const { address, chain } = useAccount()
  const {
    allowance,
    isLoading: isLoadingAllowance,
    refetch,
  } = useRareAllowance(
    enabled,
    spenderAddress as string,
    address as string,
    currencyAddress
  )
  const { writeContractAsync, isPending: loadingAllowance } = useWriteContract()
  const randomId = genToastId()
  const [hash, setHash] = useState<`0x${string}` | undefined>(undefined)
  const {} = useWaitForTxConfirmation({
    hash,
    onWait: () => {
      toast.loading("Confirming tx...", {
        id: randomId,
      })
    },
    onConfirmation: () => {
      toast.success("Transaction confirmed", {
        id: randomId,
      })
      setHash(undefined)
      refetch()
    },
    onError: () => {
      setHash(undefined)
      toast.error("Error confirming transaction", {
        id: randomId,
      })
    },
  })

  useEffect(() => {
    !isLoadingAllowance && refetch()
  }, [enabled])

  const setMaxAllowance = async (toastId: string): Promise<void> => {
    try {
      const maxUint256 = BigInt(convertRarestTokenToDecimals(100000000))
      const result = await writeContractAsync({
        address: currencyAddress,
        abi: RareToken,
        functionName: "increaseAllowance",
        chainId: chain?.id,
        args: [spenderAddress, maxUint256],
      })
      refetch()
      result && setHash(result)
    } catch (error) {
      const { message, options } = TxToastError(error as Error, toastId)
      toast.error(message, options)
    }
  }
  return {
    setMaxAllowance,
    isLoadingAllowance: isLoadingAllowance || loadingAllowance,
    allowance,
  }
}

import { format } from "date-fns"
import { Popover } from "./popover"
import { Input } from "./input"
import { Icon } from "./icon"
import { TimeSelector } from "./time"
import { isMobile } from "react-device-detect"
import { useEffect, useState } from "react"
import { cn } from "@/utils/cn"

interface TimePickerProps {
  value: string
  onChange: (value: string) => void
  label: string
  placeholder: string
  disabled?: boolean
  fromCurrentTime?: boolean
  unwrapped?: boolean
  className?: string
}

const ModalTimePicker = ({
  content,
  trigger,
  className,
}: {
  content: JSX.Element
  trigger: JSX.Element
  className?: string
}): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false)
  // set is close when click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      if (!target.closest(".time-picker-container")) {
        setIsOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])
  return (
    <div className="time-picker-container relative">
      <button
        className="relative flex w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => {
          if (e.key === "Enter" || e.key === " ") {
            setIsOpen(!isOpen)
          }
        }}
        tabIndex={0}
      >
        {trigger}
      </button>
      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          className={cn(
            "absolute left-3 z-10 mt-2 rounded border border-sr-border-primary bg-sr-bg-primary p-4",
            className && className
          )}
        >
          {content}
        </div>
      )}
    </div>
  )
}

export const WrappedTimePicker = ({
  disabled,
  content,
  trigger,
  unwrapped,
  className,
}: {
  disabled?: boolean
  trigger: JSX.Element
  content: JSX.Element
  unwrapped: boolean
  className?: string
}): JSX.Element => {
  switch (unwrapped) {
    case false:
      return (
        <Popover>
          <Popover.Trigger
            className="relative w-full text-left"
            disabled={disabled}
          >
            {trigger}
          </Popover.Trigger>
          <Popover.Content
            style={{ zIndex: 9999 }}
            align="end"
            sideOffset={-14}
            alignOffset={0}
          >
            {content}
          </Popover.Content>
        </Popover>
      )
      break

    default:
      return (
        <ModalTimePicker
          className={className}
          content={content}
          trigger={trigger}
        />
      )
      break
  }
}

export const TimePicker = ({
  value,
  onChange,
  label,
  placeholder,
  disabled,
  fromCurrentTime,
  unwrapped,
  className,
}: TimePickerProps): JSX.Element => {
  let formattedTime = ""
  if (value) {
    const [hours, minutes] = value.split(":").map(Number)
    if (
      !isNaN(hours) &&
      !isNaN(minutes) &&
      hours >= 0 &&
      hours < 24 &&
      minutes >= 0 &&
      minutes < 60
    ) {
      const date = new Date()
      date.setHours(hours)
      date.setMinutes(minutes)
      formattedTime = format(date, "HH:mm")
    }
  }

  return isMobile ? (
    <Input
      id="mobileInput"
      type="time"
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
    />
  ) : (
    <WrappedTimePicker
      disabled={disabled}
      trigger={
        <>
          <Input
            id="desktopInput"
            icon="clock"
            label={label}
            placeholder={placeholder}
            value={formattedTime}
            disabled={disabled}
          />
          {formattedTime && !disabled && (
            <span
              role="button"
              tabIndex={0}
              onClick={() => onChange("")}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  e.preventDefault()
                  onChange("")
                }
              }}
              className="absolute right-3 top-[34px] cursor-pointer text-day-grey-2"
              aria-label="Clear date"
            >
              <Icon name="close2" />
            </span>
          )}
        </>
      }
      content={
        <TimeSelector
          selected={value}
          onSelect={(time) => onChange(time)}
          fromCurrentTime={fromCurrentTime}
        />
      }
      unwrapped={!!unwrapped}
      className={className}
    />
  )
}

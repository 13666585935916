"use client"

import * as React from "react"
import {
  RainbowKitProvider,
  Theme,
  getDefaultConfig,
} from "@rainbow-me/rainbowkit"
import {
  trustWallet,
  ledgerWallet,
  phantomWallet,
  metaMaskWallet,
  rabbyWallet,
  coinbaseWallet,
  walletConnectWallet,
  rainbowWallet,
  braveWallet,
  okxWallet,
} from "@rainbow-me/rainbowkit/wallets"
import { http, WagmiProvider } from "wagmi"
import { mainnet, sepolia } from "wagmi/chains"
// import { publicProvider } from "wagmi/providers/public"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { httpBatchLink, httpLink, splitLink } from "@trpc/client"
import { useState } from "react"
import { trpc } from "@/web/clients/trpc"
import { WC_PROJECT_ID } from "@/constants/environment"
import { useThemeStore } from "@/web/stores/theme"
import superjson from "superjson"
import { mainnetRpcUrl, sepoliaRpcUrl } from "@/utils/chainClients"

const projectId = WC_PROJECT_ID || "PROJECT_ID"

const wallets = [
  {
    groupName: "Recommended",
    wallets: [
      () => metaMaskWallet({ projectId }),
      () => coinbaseWallet({ appName: "SuperRare" }),
      () => rabbyWallet(),
      () => phantomWallet(),
      () => trustWallet({ projectId }),
      () => ledgerWallet({ projectId }),
    ],
  },
  {
    groupName: "Other",
    wallets: [
      () => braveWallet(),
      () => walletConnectWallet({ projectId }),
      () => rainbowWallet({ projectId }),
      () => okxWallet({ projectId }),
    ],
  },
]

const mkWagmiConfig = () =>
  getDefaultConfig({
    appName: "SuperRare",
    projectId,
    wallets: wallets,
    chains: [mainnet, sepolia],
    transports: {
      [mainnet.id]: http(mainnetRpcUrl),
      [sepolia.id]: http(sepoliaRpcUrl),
    },
    ssr: true,
  })

export const fetcher = async (
  info: RequestInfo | URL,
  options: RequestInit | undefined
) => {
  const response = await fetch(info, options)

  if (response.status === 401) {
    try {
      const refreshResponse = await fetch(`/api/next/auth/refresh`, {
        method: "POST",
      })

      if (refreshResponse.status == 200) {
        return await fetch(info, options)
      } else {
        await fetch("/api/next/auth/logout", {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
        })
      }
    } catch (error) {
      return response
    }
  }

  return response
}

export const TrpcProvider: React.FC<{ children: React.ReactNode }> = (p) => {
  const [queryClient] = useState(() => new QueryClient())
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        splitLink({
          condition(op) {
            // check for context property `skipBatch`
            return Boolean(op.context.skipBatch)
          },
          // when condition is true, use normal request
          true: httpLink({
            url: "/api/trpc",
            transformer: superjson,
            fetch: fetcher,
          }),
          // when condition is false, use batching
          false: httpBatchLink({
            url: "/api/trpc",
            transformer: superjson,
            fetch: fetcher,
          }),
        }),
      ],
    })
  )
  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {p.children}
      </QueryClientProvider>
    </trpc.Provider>
  )
}

const SrLightTheme: Theme = {
  blurs: {
    modalOverlay: "0px",
  },
  colors: {
    accentColor: "#000",
    accentColorForeground: "#fff",
    actionButtonBorder: "#fff",
    actionButtonBorderMobile: "#fff",
    actionButtonSecondaryBackground: "#aaa",
    closeButton: "#fff",
    closeButtonBackground: "#000",
    connectButtonBackground: "#aaa",
    connectButtonBackgroundError: "#aaa",
    connectButtonInnerBackground: "#aaa",
    connectButtonText: "#fff",
    connectButtonTextError: "#aaa",
    connectionIndicator: "#aaa",
    downloadBottomCardBackground: "#aaa",
    downloadTopCardBackground: "#aaa",
    error: "#aaa",
    generalBorder: "#000",
    generalBorderDim: "#000",
    menuItemBackground: "#eee",
    modalBackdrop: "rgba(255,255,255, 0.5)",
    modalBackground: "#fff",
    modalBorder: "#000",
    modalText: "#000",
    modalTextDim: "#aaa",
    modalTextSecondary: "#aaa",
    profileAction: "#aaa",
    profileActionHover: "#aaa",
    profileForeground: "#aaa",
    selectedOptionBorder: "#aaa",
    standby: "#aaa",
  },
  fonts: {
    body: "Inter",
  },
  radii: {
    actionButton: "4px",
    connectButton: "4px",
    menuButton: "4px",
    modal: "4px",
    modalMobile: "4px",
  },
  shadows: {
    connectButton: "4px",
    dialog: "4px",
    profileDetailsAction: "4px",
    selectedOption: "4px",
    selectedWallet: "4px",
    walletLogo: "4px",
  },
}

const SrDimTheme: Theme = {
  blurs: {
    modalOverlay: "0px",
  },
  colors: {
    accentColor: "#aaa",
    accentColorForeground: "#fff",
    actionButtonBorder: "#333333",
    actionButtonBorderMobile: "#333333",
    actionButtonSecondaryBackground: "#aaa",
    closeButton: "#333333",
    closeButtonBackground: "#aaa",
    connectButtonBackground: "#aaa",
    connectButtonBackgroundError: "#aaa",
    connectButtonInnerBackground: "#aaa",
    connectButtonText: "#fff",
    connectButtonTextError: "#aaa",
    connectionIndicator: "#aaa",
    downloadBottomCardBackground: "#aaa",
    downloadTopCardBackground: "#aaa",
    error: "#aaa",
    generalBorder: "#333333",
    generalBorderDim: "#333333",
    menuItemBackground: "#272727",
    modalBackdrop: "rgba(0, 0, 0, 0.5)",
    modalBackground: "#212121",
    modalBorder: "#333333",
    modalText: "#fff",
    modalTextDim: "#aaa",
    modalTextSecondary: "#aaa",
    profileAction: "#aaa",
    profileActionHover: "#aaa",
    profileForeground: "#aaa",
    selectedOptionBorder: "#aaa",
    standby: "#aaa",
  },
  fonts: {
    body: "Inter",
  },
  radii: {
    actionButton: "4px",
    connectButton: "4px",
    menuButton: "4px",
    modal: "4px",
    modalMobile: "4px",
  },
  shadows: {
    connectButton: "4px",
    dialog: "4px",
    profileDetailsAction: "4px",
    selectedOption: "4px",
    selectedWallet: "4px",
    walletLogo: "4px",
  },
}

const SrDarkTheme: Theme = {
  blurs: {
    modalOverlay: "0px",
  },
  colors: {
    accentColor: "#aaa",
    accentColorForeground: "#fff",
    actionButtonBorder: "#333333",
    actionButtonBorderMobile: "#333333",
    actionButtonSecondaryBackground: "#aaa",
    closeButton: "#333333",
    closeButtonBackground: "#aaa",
    connectButtonBackground: "#aaa",
    connectButtonBackgroundError: "#aaa",
    connectButtonInnerBackground: "#aaa",
    connectButtonText: "#fff",
    connectButtonTextError: "#aaa",
    connectionIndicator: "#aaa",
    downloadBottomCardBackground: "#aaa",
    downloadTopCardBackground: "#aaa",
    error: "#aaa",
    generalBorder: "#fff",
    generalBorderDim: "#fff",
    menuItemBackground: "#272727",
    modalBackdrop: "rgba(0, 0, 0, 0.5)",
    modalBackground: "#000",
    modalBorder: "#fff",
    modalText: "#fff",
    modalTextDim: "#aaa",
    modalTextSecondary: "#aaa",
    profileAction: "#aaa",
    profileActionHover: "#aaa",
    profileForeground: "#aaa",
    selectedOptionBorder: "#aaa",
    standby: "#aaa",
  },
  fonts: {
    body: "Inter",
  },
  radii: {
    actionButton: "4px",
    connectButton: "4px",
    menuButton: "4px",
    modal: "4px",
    modalMobile: "4px",
  },
  shadows: {
    connectButton: "4px",
    dialog: "4px",
    profileDetailsAction: "4px",
    selectedOption: "4px",
    selectedWallet: "4px",
    walletLogo: "4px",
  },
}
export function Providers({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const [queryClient] = useState(new QueryClient())
  const [wagmiConfig] = useState(mkWagmiConfig())
  const [mounted, setMounted] = React.useState(false)
  React.useEffect(() => setMounted(true), [])
  const { mode } = useThemeStore()
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider
          modalSize="compact"
          theme={
            mode == "dim"
              ? SrDimTheme
              : mode == "dark"
                ? SrDarkTheme
                : SrLightTheme
          }
        >
          {mounted && children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

import useCurrencyStore from "@/web/stores/currency"
import { Currency } from "@/utils/currency"
import {
  EthUsdCurrency,
  RareUsdCurrency,
  UsdcCurrencyUsd,
} from "@/utils/priceUtils"
import { useEffect, useState } from "react"

export const useCurrencyUsd = ({
  amount,
  currency,
  decimals,
  historicalUsd,
  withFee,
}: {
  amount: bigint
  currency: Currency
  historicalUsd?: number
  decimals?: number
  withFee?: boolean
}): {
  price: string
} => {
  const { ethPrice, rarePrice } = useCurrencyStore()
  const [price, setPrice] = useState("")

  useEffect(() => {
    if (currency?.symbol === "ETH") {
      setPrice(
        EthUsdCurrency(amount, ethPrice, historicalUsd, decimals, withFee)
      )
      return
    } else if (currency?.symbol === "RARE") {
      setPrice(
        RareUsdCurrency(amount, rarePrice, historicalUsd, decimals, withFee)
      )
      return
    } else if (currency?.symbol === "USDC") {
      setPrice(UsdcCurrencyUsd(amount, decimals, withFee))
      return
    } else {
      setPrice("")
    }
  }, [amount, currency, ethPrice, rarePrice, historicalUsd, decimals, withFee])

  return {
    price,
  }
}

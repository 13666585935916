import { TxToastError } from "@/utils/txUtils"
import toast from "react-hot-toast"
import { useAccount, useWriteContract } from "wagmi"
import { useIsContractApproved } from "./useIsApproved"
import { genToastId } from "@/utils/toast"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { zeroAddress } from "viem"
import { getDefaultMarketplaceTokenAbi } from "@/utils/getTokenAbi"
import { useTransactionModalStore } from "@/web/stores/transactionModal"
import { SubmitSplits } from "./useConfigureAuction"
import { useMemo } from "react"

export type AcceptOfferToken = Pick<
  Erc721Token,
  "contract_address" | "token_id" | "erc721_owner"
>

export const useAcceptOffer = ({
  token,
  submitSplits,
  isWaiting,
}: {
  token: AcceptOfferToken & {
    current_offer: {
      currency_address: string
      amount: number | bigint
    }[]
  }
  submitSplits?: SubmitSplits
  isWaiting?: boolean
}): {
  acceptOffer: (onSubmit?: () => void) => Promise<void>
  isLoading: boolean
  isApproved: any
} => {
  const { address, chain } = useAccount()
  const splitAddresses = [address || "0x"]
  const splitRatios = [100]
  const splitsToSubmit = useMemo(
    () => ({
      splitAddresses: submitSplits?.splitAddresses
        ? [...submitSplits?.splitAddresses, splitAddresses[0]]
        : splitAddresses,
      splitRatios: submitSplits?.splitRatios
        ? [
            ...submitSplits?.splitRatios,
            splitRatios[0] - submitSplits.totalRatios,
          ]
        : splitRatios,
    }),
    [submitSplits]
  )

  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()
  const {
    isApproved,
    approve,
    isLoading: isLoadingIsApproved,
  } = useIsContractApproved({
    ownerAddress: address as string,
    contractAddress: token?.contract_address as string,
  })
  const { setHash, setToastId } = useTransactionModalStore()
  const acceptOffer = async (onSubmit?: () => void): Promise<void> => {
    const randomId = genToastId()
    if (!writeContractAsync) {
      toast.error("Error: writeContractAsync " + errorContract)
      return
    }
    try {
      if (!isApproved) {
        toast.loading("Approving contract...", { id: randomId })
        const tx = await approve()
        toast.success("Contract approved", { id: randomId })
        tx && (setHash(tx), setToastId(randomId))
        return
      }
      toast.loading("Awaiting user confirmation...", { id: randomId })

      const runTx = async () => {
        const abiDetails = getDefaultMarketplaceTokenAbi(
          token?.contract_address as string,
          chain?.id || 1
        )

        switch (abiDetails._type) {
          case "SuperRareV1": {
            const result = await writeContractAsync({
              address: abiDetails?.contract,
              abi: abiDetails?.abi,
              functionName: "acceptBid",
              args: [BigInt(token?.token_id)],
            })
            return result
          }
          case "Bazaar": {
            const result = await writeContractAsync({
              address: abiDetails?.contract,
              abi: abiDetails?.abi,
              functionName: "acceptOffer",
              args: [
                token?.contract_address as `0x${string}`,
                BigInt(token?.token_id),
                (token?.current_offer?.[0]?.currency_address ||
                  zeroAddress) as `0x${string}`,
                BigInt(token?.current_offer?.[0]?.amount || 0),
                splitsToSubmit?.splitAddresses,
                splitsToSubmit?.splitRatios,
              ],
            })
            return result
          }
        }
      }
      const result = await runTx()
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error: any) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return {
    acceptOffer,
    isLoading: isPending || isLoadingIsApproved || !!isWaiting,
    isApproved,
  }
}

import { useMakeOffer } from "@/web/hooks/useMakeOffer"
import { useProfileStore } from "@/web/stores/profile"

import { InputWithCurrency } from "./InputWithCurrency"
import { isNumber } from "lodash"
import {
  ALLOWANCE_COPY,
  CurrencyDisplay,
  DataRow,
  FixedMobileButton,
  Terms,
  balanceMessage,
  symbolSwap,
} from "./SharedElements"
import { CurrencyBalanceFormat } from "../connectButton/connectButton"
import { Currency, getCurrencyByAddress, parseCurrency } from "@/utils/currency"
import HR from "@/ui/hr"
import { Loader } from "@/ui/loader"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { checkIsV1Token } from "@/utils/getTokenAbi"
import { useModal } from "@/web/stores/infoModal"
import { useCurrencyBalances } from "@/web/hooks/useCurrencyBalances"
import { formatUnits, parseUnits, zeroAddress } from "viem"
import { useChainId } from "wagmi"
import { getHighestOffer } from "@/utils/priceUtils"
import useCurrencyStore from "@/web/stores/currency"

export const OfferContent = ({
  amount,
  setAmount,
  closeModal,
  selectedCurrency,
  setSelectedCurrency,
  token,
  approvedTerms,
  setApprovedTerms,
}: {
  amount: string | number
  setAmount: (amount: string | number) => void
  closeModal?: () => void
  selectedCurrency: Currency
  setSelectedCurrency: (currency: Currency["symbol"]) => void
  token: Erc721Token
  approvedTerms: boolean
  setApprovedTerms: (approvedTerms: boolean) => void
}): JSX.Element => {
  const { ethPrice, rarePrice } = useCurrencyStore()
  const { profile } = useProfileStore()
  const chain = useChainId()
  const { balance, hasEnoughBalance } = useCurrencyBalances({
    amountWei: parseUnits(String(amount), selectedCurrency.decimals),
    selectedCurrency,
  })
  const { setType, setIsOpen } = useModal()
  const { isLoading, makeOffer, enoughAllowance } = useMakeOffer({
    token: token,
    weiPrice: parseCurrency(String(amount), selectedCurrency) || 0n,
    currencyAddress: selectedCurrency.address as `0x${string}`,
  })
  const onOffer = () => {
    closeModal?.()
    if (!profile?.username) {
      setType("complete-profile")
      setIsOpen(true)
    }
  }
  const currentOffer = getHighestOffer({
    offers: token?.current_offer || [],
    rarePrice,
    ethPrice,
  })
  const currentOfferCurrency = getCurrencyByAddress(
    currentOffer?.currency?.address || zeroAddress
  )
  const currentOfferAmount = Number(
    formatUnits(
      BigInt(currentOffer?.amount || 0),
      currentOfferCurrency.decimals
    )
  )
  const showCurrentOffer =
    !!currentOffer && currentOfferCurrency.address === selectedCurrency.address

  const isOfferLowerThanCurrent =
    showCurrentOffer && Number(amount) <= currentOfferAmount

  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div>
        <InputWithCurrency
          id="modal-bid-amount"
          amount={amount}
          setAmount={setAmount}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
          label="your offer"
          hasSuccess={isNumber(amount) && amount > 0}
        />
        <div className="mt-6 gap-1">
          <DataRow label="Your balance">
            <CurrencyBalanceFormat
              className="text-sm uppercase text-sr-text-primary"
              balance={balance}
              decimals={3}
              isFormatted
              symbol={symbolSwap(selectedCurrency.symbol)}
              symbolClass="text-sm"
            />
          </DataRow>
          {showCurrentOffer && (
            <DataRow label="Highest offer">
              <CurrencyDisplay
                amount={currentOfferAmount}
                currency={currentOfferCurrency}
                withFee={!checkIsV1Token(token.contract_address, chain)}
              />
            </DataRow>
          )}
          <DataRow
            label="Total offer amount:"
            gray
            error={
              hasEnoughBalance
                ? undefined
                : balanceMessage(selectedCurrency.symbol)
            }
          >
            <CurrencyDisplay
              amount={amount}
              currency={selectedCurrency}
              withFee={!checkIsV1Token(token.contract_address, 1)}
            />
          </DataRow>
        </div>
      </div>
      <div className="mt-10">
        <HR />
        <p className="my-3 text-sm text-sr-text-secondary">
          Your {selectedCurrency?.symbol}, including the marketplace fee, will
          be escrowed in the smart contract until your offer is accepted or you
          withdraw it.
        </p>
        {!profile?.username && (
          <Terms
            approvedTerms={approvedTerms}
            setApprovedTerms={setApprovedTerms}
          />
        )}
        <FixedMobileButton
          onClick={() => makeOffer(onOffer)}
          disabled={
            !!profile?.username &&
            !approvedTerms &&
            (isLoading ||
              amount === "" ||
              Number(amount) <= 0 ||
              !hasEnoughBalance ||
              isOfferLowerThanCurrent)
          }
        >
          {isLoading ? (
            <Loader kind="simple" />
          ) : !enoughAllowance ? (
            ALLOWANCE_COPY
          ) : (
            "SUBMIT OFFER"
          )}
        </FixedMobileButton>
      </div>
    </div>
  )
}

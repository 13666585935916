import { Erc721Token } from "@/graphql/types/Erc721Token"
import { genToastId } from "@/utils/toast"
import toast from "react-hot-toast"
import { zeroAddress } from "viem"
import { useAccount, useWriteContract } from "wagmi"
import { useIsContractApproved } from "./useIsApproved"
import { TxToastError } from "@/utils/txUtils"
import {
  checkIsV1Token,
  getDefaultMarketplaceTokenAbi,
} from "@/utils/getTokenAbi"
import { useTransactionModalStore } from "@/web/stores/transactionModal"
import { SubmitSplits } from "./useConfigureAuction"
import { useMemo } from "react"

export type SaleToken = Pick<
  Erc721Token,
  "contract_address" | "token_id" | "erc721_owner"
>
export const useSetSalePrice = ({
  token,
  weiPrice,
  currencyAddress,
  targetAddress,
  submitSplits,
}: {
  token: SaleToken
  marketAddress?: `0x${string}`
  weiPrice: number
  currencyAddress: `0x${string}`
  targetAddress?: `0x${string}`
  submitSplits?: SubmitSplits
}): {
  setSalePrice: (onSubmit?: () => void) => void
  isLoading: boolean
  isApproved: boolean
} => {
  const { setHash, setToastId } = useTransactionModalStore()
  const { address, chain } = useAccount()
  const isV1Token = checkIsV1Token(token.contract_address, chain?.id || 1)
  const {
    isApproved,
    approve,
    isLoading: isLoadingIsApproved,
  } = useIsContractApproved({
    ownerAddress: address as string,
    contractAddress: token?.contract_address as string,
  })

  const splitRatios = [100]
  const splitAddresses = [address as `0x${string}`]

  const splitsToSubmit = useMemo(
    () => ({
      splitAddresses: submitSplits?.splitAddresses
        ? [...submitSplits?.splitAddresses, splitAddresses[0]]
        : splitAddresses,
      splitRatios: submitSplits?.splitRatios
        ? [
            ...submitSplits?.splitRatios,
            splitRatios[0] - submitSplits.totalRatios,
          ]
        : splitRatios,
    }),
    [submitSplits]
  )
  const randomId = genToastId()

  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()

  const setSalePrice = async (onSubmit?: () => void): Promise<void> => {
    try {
      if (!isApproved && !isV1Token) {
        toast.loading("Approving contract...", { id: randomId })
        await approve()
        toast.success("Contract approved", { id: randomId })
        return
      }
      if (!writeContractAsync) {
        toast.error("Error: writeContractAsync or Args " + errorContract)
        return
      }

      toast.loading("Awaiting user confirmation...", { id: randomId })

      const runTx = async () => {
        const abiDetails = getDefaultMarketplaceTokenAbi(
          token.contract_address,
          chain?.id || 1
        )
        switch (abiDetails._type) {
          case "SuperRareV1": {
            const result = await writeContractAsync({
              address: abiDetails?.contract,
              abi: abiDetails?.abi,
              functionName: "setSalePrice",
              args: [BigInt(token.token_id), BigInt(weiPrice)],
            })
            return result
          }
          case "Bazaar": {
            const result = await writeContractAsync({
              address: abiDetails?.contract,
              abi: abiDetails?.abi,
              functionName: "setSalePrice",
              args: [
                token.contract_address as `0x${string}`,
                BigInt(token.token_id),
                currencyAddress,
                BigInt(weiPrice),
                targetAddress ?? zeroAddress,
                splitsToSubmit.splitAddresses,
                splitsToSubmit.splitRatios,
              ],
            })
            return result
          }
        }
      }
      const result = await runTx()

      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return {
    setSalePrice,
    isLoading: isPending || isLoadingIsApproved,
    isApproved: isApproved || isV1Token,
  }
}

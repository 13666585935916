"use client"

import {
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY,
  CHAIN_ID,
} from "@/constants/environment"
import { AvatarProfile } from "@/ui/avatarProfile"
import { Icon } from "@/ui/icon"
import { chainIdToSlug } from "@/utils/chains"
import algoliasearch from "algoliasearch/lite"
import { AnimatePresence, motion } from "framer-motion"
import Image from "next/image"
import Link from "next/link"
import { useEffect, useState } from "react"
import { createPortal } from "react-dom"
import {
  InstantSearch,
  SearchBox,
  Hits,
  HitsPerPage,
  Highlight,
  Index,
  useStats,
} from "react-instantsearch"
import { useToggle } from "react-use"
import { useRouter } from "next/navigation"
import { useExploreStore } from "@/web/stores/explore"
import { cn } from "@/utils/cn"

const searchClient = algoliasearch(
  ALGOLIA_APP_ID as string,
  ALGOLIA_SEARCH_KEY as string
)

const ArtworkHit = ({ hit }: { hit: any }): JSX.Element => {
  return (
    <div className="group flex gap-3">
      <Link
        href={`/artwork/${chainIdToSlug(CHAIN_ID) || "eth"}/${hit.contractAddress}/${hit.tokenId}`}
      >
        <div className="relative size-12 overflow-hidden rounded-md bg-sr-bg-tertiary">
          {hit?.thumbnailImage && (
            <Image
              src={hit?.thumbnailImage}
              className={`
                duration-300
                group-hover:scale-110
              `}
              fill
              alt={hit.title}
            />
          )}
        </div>
      </Link>
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="relative w-full truncate overflow-ellipsis">
          <Link
            href={`/artwork/${chainIdToSlug(CHAIN_ID) || "eth"}/${hit.contractAddress}/${hit.tokenId}`}
          >
            <Highlight
              className="hover:underline"
              classNames={{
                highlighted: "bg-white rounded-md",
              }}
              attribute="name"
              hit={hit}
            />
          </Link>
        </div>
        <a
          className={`
            text-sm text-sr-text-secondary
            hover:underline
          `}
          href={`/${hit.owner?.username}`}
        >
          @{hit.owner?.username}
        </a>
      </div>
    </div>
  )
}

const PersonHit = ({ hit }: { hit: any }): JSX.Element => (
  <div className="flex gap-3">
    <AvatarProfile user={hit} size="md" />
  </div>
)

const SeriesHit = ({ hit }: { hit: any }): JSX.Element => {
  return (
    <div className="group flex gap-3">
      <div className="relative size-12 overflow-hidden rounded-md bg-sr-bg-tertiary">
        {hit?.coverImage && (
          <Link href={`/series/${hit.tokenContractAddress}`} target="_blank">
            <Image
              src={hit?.coverImage}
              className={`
                duration-300
                group-hover:scale-110
              `}
              fill
              alt={hit.title}
            />
          </Link>
        )}
      </div>
      <div className="flex flex-1 flex-col overflow-hidden">
        <div className="relative w-full truncate overflow-ellipsis">
          <Link
            onClick={(e) => {
              e.stopPropagation()
            }}
            href={`/series/${hit.tokenContractAddress}`}
            target="_blank"
          >
            <Highlight
              className="hover:underline"
              classNames={{
                highlighted: "bg-white rounded-md",
              }}
              attribute="name"
              hit={hit}
            />
          </Link>
        </div>
        <p className="text-sm text-sr-text-secondary">
          {hit.artworks} artworks
        </p>
      </div>
    </div>
  )
}

const SearchStats = ({
  title,
  href,
  searchInput,
}: {
  title: string
  href?: string
  searchInput?: string
}): JSX.Element => {
  const { nbHits } = useStats()
  const { setFilters } = useExploreStore()
  const router = useRouter()

  const handleClick = async (): Promise<void> => {
    router.push(href || "/explore")
    await new Promise((resolve) => setTimeout(resolve, 100))
    setFilters({
      artworks: { tags: {} },
      series: { categories: {} },
      search: searchInput,
    })
  }

  return (
    <h2 className="mt-4 uppercase text-sr-text-tertiary">
      {href ? (
        <button className="hover:underline" onClick={handleClick}>
          {title} ({nbHits.toLocaleString()})
        </button>
      ) : (
        <>
          {" "}
          {title} ({nbHits.toLocaleString()}){" "}
        </>
      )}{" "}
    </h2>
  )
}

const SearchIndex = ({
  indexName,
  href,
  hitComponent,
  title,
  perPage = 6,
  onClick,
  searchInput,
}: {
  indexName: string
  href?: string
  hitComponent: any
  title: string
  perPage?: number
  onClick?: () => void
  searchInput?: string
}): JSX.Element => {
  const handleClick = (e: any) => {
    e.preventDefault()
    onClick?.()
  }
  return (
    <Index indexName={indexName}>
      <HitsPerPage
        className="hidden bg-sr-bg-primary"
        classNames={{
          select: "bg-sr-bg-primary text-sr-text-primary",
        }}
        items={[
          { label: `${perPage} per page`, value: perPage, default: true },
        ]}
      />
      <SearchStats title={title} href={href} searchInput={searchInput} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Hits
          onClick={handleClick}
          classNames={{
            list: "grid lg:grid-cols-2 gap-4",
          }}
          hitComponent={hitComponent}
        />
      </motion.div>
    </Index>
  )
}

const SearchContent = ({ onClick }: { onClick?: () => void }): JSX.Element => {
  const [isFocused, setIsFocused] = useState(false)
  const [isInputEmpty, setIsInputEmpty] = useToggle(true)
  const [searchInput, setSearchInput] = useState("")
  const [tags, setTags] = useState<{ name: string; url: string }[]>([])

  const fetchTags = async (): Promise<void> => {
    try {
      const response = await searchClient
        .initIndex("prod_artworks")
        .searchForFacetValues("metadata.tags", searchInput, {
          maxFacetHits: 15,
          sortFacetValuesBy: "count",
        })
      const newTags = response.facetHits.map((tag: any) => ({
        name: tag.value,
        url: `/explore?artworks[tags][${tag.value}]=true&series[categories][${tag.value}]=true&search=`,
      }))
      setTags(newTags)
    } catch (error) {
      //console.error("Error fetching tags:", error)
    }
  }

  useEffect(() => {
    fetchTags()
  }, [searchInput, isFocused])

  const router = useRouter()
  const { setFilters } = useExploreStore()

  return (
    <>
      {isFocused &&
        createPortal(
          <div
            className={`
              fixed left-0 top-0 z-10 hidden h-full w-full backdrop-blur-sm bg-sr-bg-primary/50
              lg:block
            `}
          ></div>,
          document.body
        )}
      <div
        className={`
          relative z-20 h-dvh overflow-y-auto overscroll-none
          [contain:layout]
          [lg:contain:none]
          lg:h-auto lg:overflow-y-visible
        `}
      >
        <div
          className={`
            sticky top-0 z-20 flex w-full items-center bg-sr-bg-primary p-4 drop-shadow-[0_5px_5px_rgba(0,0,0,0.01)]
            lg:relative lg:bg-transparent
          `}
        >
          <SearchBox
            searchAsYouType={true}
            onChangeCapture={(e) => {
              const value = (e.target as HTMLInputElement).value
              setIsInputEmpty(value === "")
              setSearchInput(value)
            }}
            onFocus={() => {
              setIsFocused(true)
            }}
            onBlur={() => {
              setIsFocused(false)
              setIsInputEmpty(true)
              setTags([])
              setSearchInput("")
            }}
            onSubmit={async (e) => {
              e.preventDefault()
              onClick?.()

              setFilters({
                artworks: { tags: {} },
                series: { categories: {} },
                search: searchInput,
              })
              router.push(`/explore?search=${encodeURIComponent(searchInput)}`)
            }}
            placeholder="Search SuperRare"
            classNames={{
              input:
                "w-full lg:w-[256px] bg-transparent pl-8 text-sm placeholder:text-sr-text-tertiary placeholder:dim:text-sr-600 placeholder:uppercase border border-sr-border-secondary dim:border-sr-border-tertiary dim:focus:border-sr-600 focus:border-sr-border-primary rounded-md py-1.5 px-3 focus:outline-none",
              resetIcon: "hidden",
              submitIcon: "hidden",
              loadingIcon: "hidden",
              root: "w-full",
            }}
          />
          <Icon name="search" invertOnDark className="absolute left-7 size-3" />

          {/* clear input if any text */}
          {!isInputEmpty && (
            <button
              className="absolute right-7"
              onClick={() => {
                setIsInputEmpty(true)
              }}
            >
              <Icon name="close2" />
            </button>
          )}
        </div>

        <AnimatePresence>
          {isFocused && (
            <motion.div
              initial={{ opacity: 0, y: 12 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 12 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className={`
                absolute top-16 z-10 w-full overflow-y-auto rounded-md border-sr-border-primary bg-sr-bg-primary p-4
                dim:border-sr-border-tertiary
                lg:-left-8 lg:top-[74px] lg:max-h-[90dvh] lg:w-[540px] lg:border lg:p-8
              `}
            >
              <div className="flex flex-col gap-y-4 overflow-x-hidden">
                <h2 className="uppercase text-sr-text-tertiary">Tags</h2>
                <ul className="flex w-full flex-wrap gap-2">
                  {tags.map((tag) => (
                    <Link
                      className={`
                        rounded-md border border-sr-border-secondary bg-transparent px-2 py-1
                        dim:border-sr-border-tertiary dim:hover:border-sr-600
                        hover:border-sr-border-primary
                      `}
                      href={tag.url}
                      key={tag.name}
                      onClick={() => {
                        onClick?.()
                        setFilters({
                          artworks: { tags: { [tag.name]: true } },
                          series: { categories: { [tag.name]: true } },
                          search: "",
                        })
                      }}
                    >
                      {tag.name.toLowerCase()}
                    </Link>
                  ))}
                </ul>
                {!isInputEmpty && (
                  <motion.div
                    initial={{ opacity: 0, y: -12 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -12 }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                    className="flex flex-col gap-y-4"
                  >
                    <SearchIndex
                      indexName="prod_people"
                      hitComponent={PersonHit}
                      title="People"
                      perPage={6}
                      onClick={onClick}
                    />
                    <SearchIndex
                      indexName="prod_artworks"
                      href={`/explore?search=${searchInput}&tab=artworks`}
                      hitComponent={ArtworkHit}
                      title="Artworks"
                      perPage={4}
                      onClick={onClick}
                      searchInput={searchInput}
                    />
                    <SearchIndex
                      indexName="prod_series"
                      href={`/explore?search=${searchInput}&tab=series`}
                      hitComponent={SeriesHit}
                      title="Series"
                      perPage={4}
                      onClick={onClick}
                      searchInput={searchInput}
                    />
                  </motion.div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  )
}

const Search = ({ onClick }: { onClick?: () => void }): JSX.Element => {
  return (
    <>
      <div
        className={cn(
          "fixed inset-0 z-10 hidden bg-sr-bg-primary backdrop-blur-sm",
          false && "block"
        )}
      ></div>
      <div
        className={`
          overflow-y-auto
          lg:overflow-y-visible
        `}
      >
        <InstantSearch searchClient={searchClient}>
          <SearchContent onClick={onClick} />
        </InstantSearch>
      </div>
      <div
        className={`
          ml-auto
          lg:hidden
        `}
      >
        <button
          className={`
            relative flex size-9 items-center justify-center rounded-md border border-sr-border-tertiary
            dark:border-white
            dim:border-sr-700
            lg:hidden
          `}
        >
          <Icon name="search" invertOnDark />
        </button>
      </div>
    </>
  )
}

export default Search

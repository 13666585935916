import { useEffect } from "react"
import useCurrencyStore from "@/web/stores/currency"
import { trpc } from "@/web/clients/trpc"

const useEthPriceUpdater = (): void => {
  const { setEthPrice, setRarePrice } = useCurrencyStore()

  const { data: ethData } = trpc.currency.getEthPrice.useQuery()
  const { data: rareData } = trpc.currency.getRarePrice.useQuery()

  useEffect(() => {
    const updatePrice = async (): Promise<void> => {
      if (ethData?.price !== undefined) {
        try {
          const price: number = ethData.price
          setEthPrice(price)
        } catch (error) {}
      }
      if (rareData?.price !== undefined) {
        try {
          const price: number = rareData.price
          setRarePrice(price)
        } catch (error) {}
      }
    }
    updatePrice()
  }, [ethData, rareData])
}

export default useEthPriceUpdater

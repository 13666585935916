import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { useAcceptOffer } from "@/web/hooks/useAcceptOffer"
import { AvatarProfile } from "@/ui/avatarProfile"
import { getHighestOffer } from "@/utils/priceUtils"
import { FixedMobileButton, SplitsSection } from "./SharedElements"
import { Loader } from "@/ui/loader"
import { Splits } from "./TransactionModal"
import HR from "@/ui/hr"
import { useEffect, useMemo, useState } from "react"
import {
  checkIsV1Token,
  getDefaultMarketplaceTokenAbi,
} from "@/utils/getTokenAbi"
import { formatUnits, getAddress, zeroAddress } from "viem"
import { useAccount } from "wagmi"
import { getCurrencyByAddress } from "@/utils/currency"
import useCurrencyStore from "@/web/stores/currency"
import { getChainClients } from "@/utils/chainClients"
import { readContract } from "viem/actions"

export const AcceptOfferContent = ({
  token,
  closeModal,
  splits,
  setShowSplits,
  showSplits,
  isWaiting,
}: {
  token: Erc721Token
  closeModal?: () => void
  splits: Splits[]
  showSplits: boolean
  setShowSplits: (showSplits: boolean) => void
  isWaiting: boolean
}): JSX.Element => {
  const { ethPrice, rarePrice } = useCurrencyStore()
  const [contractWeiPrice, setContractWeiPrice] = useState(0n)
  const submitSplits = useMemo(
    () => ({
      splitAddresses: splits.map((split) => split.address as `0x${string}`),
      splitRatios: splits.map((split) => split.ratio),
      totalRatios: splits.reduce((acc, split) => acc + split.ratio, 0),
    }),
    [splits]
  )
  const { chainId } = useAccount()
  const currentOffer = getHighestOffer({
    offers: token?.current_offer || [],
    rarePrice,
    ethPrice,
  })
  const marketAbi = useMemo(() => {
    return getDefaultMarketplaceTokenAbi(token.contract_address, chainId || 1)
  }, [token, chainId])

  const getContractWeiPrice = async () => {
    const client = getChainClients(chainId || 1)

    const data = await readContract(client.viem, {
      address: marketAbi.contract,
      abi: marketAbi.abi,
      functionName: "tokenCurrentOffers",
      args: [
        getAddress(token.contract_address),
        BigInt(token.token_id),
        getAddress(currentOffer?.currency?.address || zeroAddress),
      ],
    })

    return setContractWeiPrice(data?.[1])
  }

  useEffect(() => {
    getContractWeiPrice()

    return () => {
      setContractWeiPrice(0n)
    }
  }, [token])

  const currencyAddress = currentOffer?.currency?.address || zeroAddress

  const submitToken = {
    contract_address: token?.contract_address,
    token_id: token?.token_id,
    current_offer: [
      {
        currency_address: currencyAddress,
        amount: contractWeiPrice || 0n,
      },
    ],
  }

  const { acceptOffer, isLoading, isApproved } = useAcceptOffer({
    token: submitToken,
    submitSplits: submitSplits,
    isWaiting,
  })
  const bidder = currentOffer?.bidder
  const currency = getCurrencyByAddress(
    currentOffer?.currency?.address || zeroAddress
  )

  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div className="flex flex-col gap-5">
        <div>
          <p className="text-sm text-sr-text-secondary">Highest offer</p>
          <p>{formatUnits(contractWeiPrice, currency?.decimals)}</p>
        </div>
        {bidder && (
          <span className="mr-1 inline whitespace-nowrap text-sm text-sr-text-tertiary">
            Offer from{" "}
            <span className="text-sr-text-primary">
              @
              <AvatarProfile
                user={bidder}
                size="sm"
                hideAvatar
                className="inline"
              />
            </span>
          </span>
        )}
      </div>
      {!checkIsV1Token(getAddress(token.contract_address), chainId || 1) && (
        <SplitsSection
          splits={splits}
          setShowSplitConfig={setShowSplits}
          className="mt-6"
        />
      )}
      <HR />
      <div>
        {!showSplits && (
          <FixedMobileButton
            onClick={() => acceptOffer(closeModal)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader kind="simple" />
            ) : !isApproved ? (
              "APPROVE CONTRACT"
            ) : (
              "ACCEPT OFFER"
            )}
          </FixedMobileButton>
        )}
      </div>
    </div>
  )
}

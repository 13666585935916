import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { useRemoveOffer } from "@/web/hooks/useRemoveOffer"
import HR from "@/ui/hr"
import { FixedMobileButton } from "./SharedElements"
import { zeroAddress } from "viem"
import useCurrencyStore from "@/web/stores/currency"
import { getHighestOffer } from "@/utils/priceUtils"

export const RemoveOfferContent = ({
  token,
  closeModal,
}: {
  token: Erc721Token
  closeModal?: () => void
}): JSX.Element => {
  const { ethPrice, rarePrice } = useCurrencyStore()
  const currentOffer = getHighestOffer({
    offers: token?.current_offer || [],
    rarePrice,
    ethPrice,
  })
  const { removeOffer } = useRemoveOffer({
    currencyOffer: (currentOffer?.currency?.address ||
      zeroAddress) as `0x${string}`,
    tokenId: token?.token_id,
    tokenContract: token?.contract_address as `0x${string}`,
  })
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div>
        <p className="text-sr-text-secondary">
          Are you sure you want to remove this offer?
        </p>
      </div>
      <div className="mt-10">
        <HR />
        <FixedMobileButton onClick={() => removeOffer(closeModal)}>
          REMOVE OFFER
        </FixedMobileButton>
      </div>
    </div>
  )
}

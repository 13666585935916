"use client"

import Image from "next/image"
import Link from "next/link"
import { Newsletter } from "./newsletter"
import { ArrowRightIcon } from "@radix-ui/react-icons"
import { SocialIcons } from "../shared/SocialIcons"
import HR from "@/ui/hr"
import { useThemeStore } from "@/web/stores/theme"

const FooterLink = ({
  href,
  children,
}: {
  href: string
  children: any
}): JSX.Element => {
  return (
    <a
      href={href}
      className={`
        text-sm
        hover:underline
      `}
    >
      {children}
    </a>
  )
}

const handleClickCookiePref = (): void => {
  const button = document.getElementsByClassName(
    "osano-cm-widget"
  )?.[0] as HTMLButtonElement
  if (button) {
    button.click()
  }
}

const openIntercom = () => {
  ;(window as any).Intercom("show")
}

export const Footer = (): JSX.Element => {
  const { footer } = useThemeStore()
  if (footer == "hidden") return <></>
  return (
    <footer
      className={`
        mt-[72px] border-t border-sr-border-primary bg-day-grey-4 pb-24 pt-12
        dark:bg-sr-850
        dim:bg-sr-900
        lg:mt-48
      `}
    >
      <div
        className={`
          flex flex-col px-8
          lg:container lg:flex-row
        `}
      >
        <div
          className={`
            flex w-full flex-col items-start
            lg:w-6/12
          `}
        >
          <Link href="/" className="relative h-[16px] w-[96px]">
            <Image
              src="/assets/logo.svg"
              className={`
                duration-300
                dark:invert
                hover:scale-95
              `}
              fill
              alt="Next logo"
            />
          </Link>
          <Newsletter />
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScTZhB9On31j-uoFzMD3hg0gGNf3hgjVyBz1xwCHsOBSydvPw/viewform"
            target="_blank"
            className="group"
          >
            Submit Artist application
            <ArrowRightIcon
              className={`
                ml-1 inline-block duration-300
                group-hover:ml-2
              `}
            />
          </a>
        </div>
        <HR
          className={`
            mb-4 border-b-sr-border-tertiary pt-4
            lg:hidden
          `}
        />
        <div
          className={`
            w-full
            lg:w-2/12
          `}
        >
          <p className="mb-4 text-sm uppercase text-typography-grey-1">
            Community
          </p>
          <nav className="flex flex-col items-start gap-y-2">
            <FooterLink href="https://help.superrare.com/">
              Help Center
            </FooterLink>
            <button
              className={`
                text-sm
                hover:underline
              `}
              onClick={openIntercom}
            >
              👋 Chat Support
            </button>
            <FooterLink href="https://www.notion.so/SuperRare-Community-Guidelines-b9c4fc521f4344a39cac7bd13d44a56f">
              Community Guidelines
            </FooterLink>
            <FooterLink href="https://superrare.notion.site/SuperRare-Bug-Bounty-Program-9f7c9e4707fa45f1acc85b1a295ba944">
              Bug Bounty Program
            </FooterLink>
          </nav>
          <nav className="mt-1 flex items-center gap-x-3">
            <SocialIcons />
          </nav>
        </div>
        <HR
          className={`
            mb-4 border-b-sr-border-tertiary pt-4
            lg:hidden
          `}
        />
        <div
          className={`
            w-full
            lg:w-2/12
          `}
        >
          <p className="mb-4 text-sm uppercase text-typography-grey-1">
            RareDao
          </p>
          <nav className="flex flex-col items-start gap-y-2">
            <FooterLink href="https://www.rare.xyz/">Rare Protocol</FooterLink>
            <FooterLink href="https://app.rare.xyz/">
              $RARE Curation Staking
            </FooterLink>
            <FooterLink href="https://forum.superrare.com/">Forum</FooterLink>
          </nav>
        </div>
        <HR
          className={`
            mb-4 border-b-sr-border-tertiary pt-4
            lg:hidden
          `}
        />
        <div
          className={`
            w-full
            lg:w-2/12
          `}
        >
          <p className="mb-4 text-sm uppercase text-typography-grey-1">Legal</p>
          <nav className="flex flex-col items-start gap-y-2">
            <FooterLink href="https://campaigns.superrare.com/terms">
              Terms of Service
            </FooterLink>
            <FooterLink href="https://campaigns.superrare.com/privacy">
              Privacy Notice
            </FooterLink>
            <FooterLink href="mailto:ip@superrare.com">
              Report content
            </FooterLink>
            <button
              onClick={handleClickCookiePref}
              className={`
                border-0 text-sm
                hover:underline
              `}
            >
              Cookie Preferences
            </button>
            <p className="text-sm text-typography-grey-2">© 2024 SuperRare</p>
          </nav>
        </div>
      </div>
    </footer>
  )
}

import { useEffect, useState } from "react"
import { useToggle } from "react-use"
import { trpc } from "@/web/clients/trpc"
import { User } from "@/graphql/generated/apollo/graphql"
import toast from "react-hot-toast"
import { heapTrack } from "@/utils/heap"
import { useAccount } from "wagmi"
import { useProfileStore } from "@/web/stores/profile"
import { useConnectModal } from "@rainbow-me/rainbowkit"

export const useFollowers = (followed_user: User, onComplete?: () => void) => {
  const { isConnected } = useAccount()
  const { profile: account } = useProfileStore()
  const { openConnectModal: showDialog } = useConnectModal()
  const [isFollowing, setIsFollowing] = useState(false)
  const [isLoading, setIsLoading] = useToggle(false)
  const [isMutating, setIsMutating] = useToggle(false)

  const utils = trpc.useUtils()

  const {
    data,
    isLoading: queryIsLoading,
    isFetching,
  } = trpc.profile.isFollowingUser.useQuery(
    {
      username: account?.username || "",
      followed_user_address: followed_user?.ethereum_address,
    },
    {
      enabled:
        isConnected && !!account?.username && !!followed_user?.ethereum_address,
    }
  )

  useEffect(() => {
    if (
      !isConnected ||
      !account?.username ||
      !followed_user?.ethereum_address
    ) {
      setIsLoading(false)
      return
    }

    setIsLoading(queryIsLoading)
    if (data) {
      setIsFollowing(data?.user_by_username?.is_user_following)
    }
  }, [data, account, queryIsLoading])

  const followMutation = trpc.profile.follow.useMutation({
    onSuccess: async () => {
      setIsFollowing((follow) => !follow)
      setIsMutating(false)
      await new Promise((resolve) => setTimeout(resolve, 500)) // wait for apollo cache to update
      utils.profile.invalidate() // invalidate and refetch profile
      if (onComplete) onComplete()
    },
  })

  const handleClickFollow = async (
    follow: boolean,
    showToast: boolean = true
  ): Promise<void> => {
    if (!isConnected) {
      showDialog?.()
      return
    }
    setIsMutating(true)

    if (!account || !followed_user) return
    await followMutation.mutateAsync({
      followedUserId: followed_user.id.toString(),
      userId: account.id.toString(),
      follow: follow,
    })
    heapTrack(
      `${follow ? "Followed" : "Unfollowed"} user ${followed_user.username}`
    )
    if (showToast) {
      follow
        ? toast.success(`You are now following @${followed_user.username}`)
        : toast(`You are no longer following @${followed_user.username}`)
    }
  }

  return {
    isLoading,
    isMutating,
    isFetching,
    isFollowing,
    handleClickFollow,
  }
}

"use client"

import {
  actionTypes,
  useTransactionModalStore,
} from "@/web/stores/transactionModal"
import { Dialog } from "@/ui/dialog"
import { useEffect, useMemo, useState } from "react"
import Image from "next/image"
import { Currency, getAllCurrencies } from "@/utils/currency"
import { getMuxUrl } from "@/utils/mux"
import HR from "@/ui/hr"
import { BidContent } from "./BidContent"
import { OfferContent } from "./OfferContent"
import { BuyContent } from "./BuyContent"
import { SetPriceContent } from "./SetPriceContent"
import { TransferContent } from "./TransferContent"
import { AuctionContent } from "./AuctionContent"
import { CancelAuctionContent } from "./CancelAuctionContent"
import { RemovePriceContent } from "./RemovePriceContent"
import { AcceptOfferContent } from "./AcceptOfferContent"
import { SettleAuctionContent } from "./SettleAuctionContent"
import { RemoveOfferContent } from "./RemoveOfferContent"
import { useAccount } from "wagmi"
import { checkIsV1Token } from "@/utils/getTokenAbi"
import { genToastId } from "@/utils/toast"
import toast from "react-hot-toast"
import useWaitForTxConfirmation from "@/web/hooks/useWaitForTxConfirmation"
import SplitsConfigContent from "./SplitsConfigContent"
import { ModelViewer } from "@/ui/modelViewer"
import { SendContent } from "./SendContent"
export type Splits = {
  ratio: number
  address: string
}
const TransactionModal = (): JSX.Element | null => {
  const [amount, setAmount] = useState<string | number>("")
  const [transferAddress, setTransferAddress] = useState<string>("")
  const [showSplitsConfig, setShowSplitsConfig] = useState(false)
  const [splits, setSplits] = useState<Splits[]>([])
  const {
    action,
    isOpen,
    token,
    defaultValue,
    setIsOpen,
    setAction,
    setToken,
    approvedTerms,
    setApprovedTerms,
    hash,
    setHash,
    toastId,
    setToastId,
    currencySymbol,
    setCurrencySymbol,
  } = useTransactionModalStore()
  const currencies = getAllCurrencies()
  type ActionFlags = {
    [K in actionTypes]: boolean
  }
  const { chain } = useAccount()
  const isV1Token = token?.contract_address
    ? checkIsV1Token(token?.contract_address as string, chain?.id || 1)
    : false

  const hasRareAllowed: ActionFlags = {
    buy: isV1Token ? false : true,
    bid: true,
    offer: true,
    price: isV1Token ? false : true,
    transfer: false,
    auction: true,
    removePrice: true,
    cancelAuction: false,
    acceptOffer: isV1Token ? false : true, //
    settleAuction: false,
    removeOffer: false,
    send: true,
  }
  const [selectedCurrency, setSelectedCurrency] = useState(() => currencies[0])

  const changeCurrency = (currency: Currency["symbol"]): void => {
    hasRareAllowed[action] &&
      setSelectedCurrency(
        currencies.find((c) => c.symbol === currency) as Currency
      )
  }

  const closeModal = (): void => {
    setAmount("")
    setToken(null)
    setAction("buy")
    setIsOpen(false)
    setSelectedCurrency(currencies[0])
    setApprovedTerms(false)
    setTransferAddress("")
    setSplits([])
    setShowSplitsConfig(false)
    setCurrencySymbol && setCurrencySymbol(undefined)
  }
  const getModalTitle = (): string => {
    if (action === "buy") return "confirm purchase"
    if (action === "bid") return "place a bid"
    if (action === "offer") return "make an offer"
    if (action === "price") return "set a list price"
    if (action === "transfer") return "transfer artwork"
    if (action === "auction") return "configure your auction"
    if (action === "removePrice") return "remove list price"
    if (action === "cancelAuction") return "cancel auction"
    if (action === "acceptOffer") return "accept offer"
    if (action === "settleAuction") return "settle auction"
    if (action === "removeOffer") return "remove offer"
    if (action === "send") return `send ${selectedCurrency?.symbol}`
    return ""
  }

  const useSplits =
    action === "auction" || action === "acceptOffer" || action === "price"
  const muxVideo = token?.nft_image?.video_clipped_mux_playback_id
  const videoUrl =
    token?.nft_image?.image_video_medium ||
    (token?.nft_image?.image_video_medium_imgix_cdn as string) ||
    (token?.erc721_metadata_media?.mime_type === "video/mp4" &&
      (token?.erc721_metadata_media?.uri as string))

  const tokenMetadata = token?.erc721_metadata?.metadata as {
    image: string
  }

  const imageUrlBase =
    token?.erc721_metadata_media?.uri ||
    tokenMetadata?.image ||
    token?.nft_image?.image_medium_imgix_cdn ||
    token?.nft_image?.image_medium ||
    (token?.nft_image?.image_small_imgix_cdn as string) ||
    (token?.erc721_metadata_media?.uri as string)

  const ipfsImage = imageUrlBase?.startsWith("ipfs://")
  const imageUrl = ipfsImage
    ? `https://ipfs.io/ipfs/${imageUrlBase?.split("/").pop()}`
    : imageUrlBase
  const randomId = useMemo(() => genToastId(), [hash])
  const isModel = token?.erc721_metadata_media?.mime_type?.includes("model")
  useEffect(() => {
    if (isOpen && defaultValue) {
      setAmount(defaultValue)
    }
    if (currencySymbol) {
      setSelectedCurrency(
        currencies.find((c) => c.symbol === currencySymbol) || currencies[0]
      )
    }
  }, [isOpen, defaultValue, currencySymbol])

  const { isWaiting } = useWaitForTxConfirmation({
    hash,
    onWait: () => {
      toast.loading("Confirming tx...", {
        id: toastId || randomId,
      })
    },
    onConfirmation: () => {
      toast.success("Transaction confirmed", {
        id: toastId || randomId,
      })
      setToastId(null)
      setHash(undefined)
    },
    onError: () => {
      setHash(undefined)
      toast.error("Error confirming transaction", {
        id: toastId || randomId,
      })
      setToastId(null)
    },
  })

  if (!isOpen || !action) return <></>

  if (action === "send") {
    return (
      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            closeModal()
          }
        }}
      >
        <Dialog.Content
          title={getModalTitle()}
          className="h-full w-auto overflow-auto p-0"
          size="2xl"
        >
          <div className="md:p-4">
            <SendContent
              closeModal={closeModal}
              selectedCurrency={selectedCurrency}
              amount={amount}
              setAmount={setAmount}
              setTransferAddress={setTransferAddress}
              transferAddress={transferAddress}
            />
          </div>
        </Dialog.Content>
      </Dialog>
    )
  }
  if (!token) return <></>
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          closeModal()
        }
      }}
    >
      <Dialog.Content
        title={getModalTitle()}
        className="h-full w-auto overflow-auto p-0"
        size="2xl"
      >
        <div
          className={`
            flex h-full flex-col
            md:flex-row md:p-4
          `}
        >
          <div className="flex w-full rounded bg-sr-bg-tertiary p-4">
            {isModel && token?.erc721_metadata_media?.uri ? (
              <ModelViewer
                src={token?.erc721_metadata_media?.uri}
                alt={token?.name}
              />
            ) : (
              <a
                className="m-auto block"
                href={`${token?.contract_address}/${token?.token_id}`}
              >
                {muxVideo || videoUrl ? (
                  <video
                    className="m-auto h-full rounded"
                    playsInline
                    src={
                      muxVideo
                        ? getMuxUrl(muxVideo, "medium")
                        : videoUrl || undefined
                    }
                    muted
                    autoPlay
                    loop
                  />
                ) : (
                  <Image
                    className={"rounded bg-cover duration-300"}
                    placeholder="empty"
                    blurDataURL={
                      (token?.nft_image?.image_blurred as string) ||
                      imageUrl ||
                      ""
                    }
                    src={imageUrl || ""}
                    alt={""}
                    width={444}
                    height={444}
                  />
                )}
              </a>
            )}
          </div>
          <div
            className={`
              relative flex h-full w-full flex-col justify-between p-4 pb-12
              md:h-auto md:pb-0
            `}
          >
            {useSplits && (
              <SplitsConfigContent
                splits={splits}
                setSplits={setSplits}
                visible={showSplitsConfig}
                setVisible={setShowSplitsConfig}
              />
            )}
            <div>
              <div className="flex items-baseline justify-between">
                <h3 className="max-w-[320px] overflow-hidden text-ellipsis text-xl">
                  {token?.name ||
                    (token?.erc721_metadata?.metadata as { name: string })
                      ?.name}
                </h3>
              </div>
              {action !== "auction" && (
                <HR
                  className={`
                    my-6
                    md:my-8
                  `}
                />
              )}
            </div>
            {action === "bid" ? (
              <BidContent
                token={token}
                amount={amount}
                setAmount={setAmount}
                closeModal={closeModal}
                approvedTerms={approvedTerms}
                setApprovedTerms={setApprovedTerms}
              />
            ) : action === "offer" ? (
              <OfferContent
                token={token}
                amount={amount}
                setAmount={setAmount}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={changeCurrency}
                closeModal={closeModal}
                approvedTerms={approvedTerms}
                setApprovedTerms={setApprovedTerms}
              />
            ) : action === "buy" ? (
              <BuyContent
                token={token}
                closeModal={closeModal}
                approvedTerms={approvedTerms}
                setApprovedTerms={setApprovedTerms}
              />
            ) : action === "price" ? (
              <SetPriceContent
                amount={amount}
                setAmount={setAmount}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={changeCurrency}
                token={token}
                closeModal={closeModal}
                splits={splits}
                showSplits={showSplitsConfig}
                setShowSplits={setShowSplitsConfig}
              />
            ) : action === "transfer" ? (
              <TransferContent
                transferAddress={transferAddress}
                setTransferAddress={setTransferAddress}
                token={token}
                closeModal={closeModal}
              />
            ) : action === "auction" ? (
              <AuctionContent
                token={token}
                amount={amount}
                setAmount={setAmount}
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={changeCurrency}
                closeModal={closeModal}
                splits={splits}
                showSplits={showSplitsConfig}
                setShowSplits={setShowSplitsConfig}
              />
            ) : action === "cancelAuction" ? (
              <CancelAuctionContent token={token} closeModal={closeModal} />
            ) : action === "removePrice" ? (
              <RemovePriceContent token={token} closeModal={closeModal} />
            ) : action === "acceptOffer" ? (
              <AcceptOfferContent
                splits={splits}
                showSplits={showSplitsConfig}
                setShowSplits={setShowSplitsConfig}
                token={token}
                closeModal={closeModal}
                isWaiting={isWaiting}
              />
            ) : action === "settleAuction" ? (
              <SettleAuctionContent token={token} closeModal={closeModal} />
            ) : action === "removeOffer" ? (
              <RemoveOfferContent token={token} closeModal={closeModal} />
            ) : null}
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  )
}

export default TransactionModal

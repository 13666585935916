import { useProfileStore } from "@/web/stores/profile"
import { Currency, formatCurrency, getCurrencyBySymbol } from "@/utils/currency"
import { useEffect, useState } from "react"
import { formatEther } from "viem"
import { useAccount, useBalance } from "wagmi"

export const useCurrencyBalances = ({
  amountWei,
  selectedCurrency,
}: {
  amountWei: bigint
  selectedCurrency: Currency
}): {
  balance: number
  hasEnoughBalance: boolean
} => {
  const { address } = useAccount()
  const { rareBalance, usdcBalance } = useProfileStore()
  const { data: balanceEth } = useBalance({
    address: address as `0x${string}`,
  })
  const [balance, setBalance] = useState(0)
  const [hasEnoughBalance, setHasEnoughBalance] = useState(false)

  const ethBalance = balanceEth?.formatted
  const USDC = getCurrencyBySymbol("USDC")
  type BalanceKeys = {
    [key: Currency["symbol"]]: number
  }

  const balances: BalanceKeys = {
    eth: Number(ethBalance),
    rare: Number(formatCurrency(BigInt(rareBalance), selectedCurrency)),
    usdc: Number(formatCurrency(BigInt(usdcBalance), USDC)),
  }

  const compareAmounts: BalanceKeys = {
    eth: Number(ethBalance || 0),
    rare: Number(formatEther(BigInt(rareBalance))),
    usdc: Number(formatCurrency(BigInt(usdcBalance), USDC)),
  }
  const formattedAmount = Number(formatCurrency(amountWei, selectedCurrency))
  useEffect(() => {
    setBalance(balances[selectedCurrency.symbol.toLowerCase()])
    setHasEnoughBalance(
      compareAmounts[selectedCurrency.symbol.toLowerCase()] >= formattedAmount
    )
  }, [selectedCurrency, amountWei, balances, compareAmounts, address])

  return { balance, hasEnoughBalance }
}

import React, { useEffect, useState } from "react"
import { Splits } from "./TransactionModal"
import { cn } from "@/utils/cn"
import HR from "@/ui/hr"
import { Input } from "@/ui/input"
import { Button } from "@/ui/button"
import { useProfileStore } from "@/web/stores/profile"
import { isAddress } from "viem"

type Props = {
  splits: Splits[]
  setSplits: (splits: Splits[]) => void
  visible: boolean
  setVisible: (visible: boolean) => void
}

const SplitRow = ({
  split,
  setLocalSplits,
  index,
  totalSplits,
  poolAddress = null,
}: {
  split: Splits
  setLocalSplits: (splits: (splits: Splits[]) => Splits[]) => void
  totalSplits: number
  index: number
  poolAddress?: null | string
}) => {
  const [address, setAddress] = useState(split.address)
  const [percentage, setPercentage] = useState(split.ratio)
  const [showCurrentRatio, setShowCurrentRatio] = useState(false)
  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (poolAddress) {
      setShowCurrentRatio(false)
      setLocalSplits((splits: Splits[]) => {
        const newSplits = [...splits]
        newSplits[index].address = poolAddress
        return newSplits
      })
      return
    }
    setShowCurrentRatio(false)
    setLocalSplits((splits: Splits[]) => {
      const newSplits = [...splits]
      newSplits[index].address = e.target.value
      return newSplits
    })
  }
  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowCurrentRatio(false)
    setLocalSplits((splits: Splits[]) => {
      const newSplits = [...splits]
      newSplits[index].ratio = Number(e.target.value)
      return newSplits
    })
  }
  const hasErrorInput =
    address != "" && !isAddress(address)
      ? {
          type: "pattern",
          message: "Invalid address",
        }
      : undefined
  return (
    <div className="mb-4 flex flex-col items-center">
      {index === 0 && (
        <div className="mb-4 flex w-full gap-4">
          <p className="w-2/3 text-sm text-sr-text-secondary">
            {poolAddress ? "COMMUNITY POOL" : "WALLET ADDRESS"}
          </p>
          <p className="w-1/3 text-sm text-sr-text-secondary">SPLIT</p>
        </div>
      )}
      <div className="flex w-full gap-4">
        <div className="w-2/3">
          {poolAddress ? (
            <p className="text-sm text-sr-text-primary">
              Split the sale with your pool, the split will be shared with all
              members based on their stake.
            </p>
          ) : (
            <Input
              id="split-address"
              type="text"
              value={address}
              onFocus={() => setShowCurrentRatio(true)}
              onChange={(e) => setAddress(e.target.value)}
              onBlur={handleAddressChange}
              hasSuccess={isAddress(address)}
              hasError={hasErrorInput}
            />
          )}
        </div>
        <div className="w-1/3">
          <Input
            id="split-percentage"
            type="number"
            value={percentage || ""}
            suffix="%"
            onFocus={() => {
              setPercentage(0), setShowCurrentRatio(true)
            }}
            onChange={(e) => setPercentage(Number(e.target.value))}
            onBlur={handlePercentageChange}
          />
        </div>
      </div>
      {showCurrentRatio && !hasErrorInput && (
        <div className="mt-2 flex w-full justify-end gap-4">
          <div className="w-2/3"></div>
          <p className={"w-1/3 text-xs text-sr-text-secondary"}>
            {100 - totalSplits}% available
          </p>
        </div>
      )}
    </div>
  )
}

const SplitsConfigContent = ({
  splits,
  setSplits,
  visible,
  setVisible,
}: Props) => {
  const defaultSplits = [
    {
      address: "",
      ratio: 0,
    },
    {
      address: "",
      ratio: 0,
    },
    {
      address: "",
      ratio: 0,
    },
  ]
  const { profile } = useProfileStore()
  const [localSplits, setLocalSplits] = useState(
    splits.length ? splits : defaultSplits
  )
  const [poolSplit, setPoolSplit] = useState<Splits[]>([
    {
      address: profile?.pool_address,
      ratio: 0,
    },
  ] as Splits[])
  const recipientsAdded = localSplits.reduce((acc, split) => {
    if (split.address !== "" && split.ratio > 0) {
      return acc + 1
    }
    return acc
  }, 0)
  const totalPercentage =
    localSplits.reduce((acc, split) => acc + split.ratio, 0) +
    poolSplit?.[0].ratio
  const hasMoreThan100 = totalPercentage > 100
  const allFieldsEmpty =
    localSplits.every((split) => split.address === "" && split.ratio === 0) &&
    poolSplit[0].ratio === 0
  const canConfirm =
    (totalPercentage <= 100 && totalPercentage > 0 && recipientsAdded > 0) ||
    allFieldsEmpty

  const submitSplits = () => {
    if (hasMoreThan100) {
      return
    }
    const newSplits = localSplits.filter(
      (split) => split.address !== "" && split.ratio > 0
    )
    if (profile?.pool_address && poolSplit[0].ratio > 0) {
      newSplits.push({
        address: profile?.pool_address,
        ratio: poolSplit[0].ratio,
      })
    }
    setSplits(newSplits)
    setVisible(false)
  }

  useEffect(() => {
    if (visible) {
      scrollTo(0, 0)
    }
  }, [visible])

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={cn(
        `
          absolute bottom-0 left-0 z-50 flex h-full w-full flex-col justify-between overflow-y-auto bg-sr-bg-primary
          bg-white px-4 py-4 pb-12 opacity-100 transition-all
          md:py-0 md:pb-0
        `,
        !visible && "pointer-events-none bottom-full overflow-hidden opacity-0"
      )}
    >
      <div className="flex h-full flex-col justify-between">
        <div>
          <p>
            Add up to 3 recipients by adding their wallet addresses below.
            <span className="text-sm text-sr-text-secondary">
              {" "}
              A recipient can be anyone with a valid wallet address.{" "}
              {/* <a
                className="underline"
                href="https://www.youtube.com/watch?v=oHg5SJYRHA0"
              >
                Need help?
              </a> */}
            </span>
          </p>
          <HR className="my-6" />
          {localSplits.map((split, index) => (
            <SplitRow
              key={index}
              split={split}
              setLocalSplits={setLocalSplits}
              index={index}
              totalSplits={localSplits.reduce(
                (acc, split) => acc + split.ratio,
                0
              )}
            />
          ))}
          <div className="mt-6 border-l pl-4 text-sm">
            <p>
              Share with a charity.{" "}
              <span className="text-sm text-sr-text-secondary">
                Browse{" "}
                <a
                  className="text-sr-text-primary underline"
                  target="_blank"
                  href="https://giveth.io/"
                  referrerPolicy="no-referrer"
                >
                  giveth.io
                </a>{" "}
                to find donation addresses for verified projects. 🩶
              </span>
            </p>
          </div>
          {profile?.pool_address && (
            <>
              <HR className="my-6" />
              <SplitRow
                poolAddress={profile?.pool_address}
                split={poolSplit[0]}
                setLocalSplits={setPoolSplit}
                index={0}
                totalSplits={localSplits.reduce(
                  (acc, split) => acc + split.ratio,
                  0
                )}
              />
            </>
          )}
        </div>
        <div>
          <HR className="mt-6" />
          {hasMoreThan100 ? (
            <p className={"mt-3 flex text-sm text-error"}>
              Percentage cannot exceed 100%
            </p>
          ) : (
            <p className="mt-3 flex text-sm text-sr-text-secondary">
              {recipientsAdded + (poolSplit?.[0]?.ratio ? 1 : 0)} recipients
              added, {totalPercentage}% shared
            </p>
          )}
          <div className="mt-6 flex justify-between gap-4">
            <Button
              kind="secondary"
              onClick={() => setVisible(false)}
              className="w-full"
            >
              Cancel
            </Button>
            <Button
              onClick={submitSplits}
              className="w-full"
              disabled={!canConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SplitsConfigContent

import { Icon } from "@/ui/icon"
import { Input } from "@/ui/input"
import { Popover } from "@/ui/popover"
import { cn } from "@/utils/cn"
import { getUsdCurrencyObject } from "@/utils/priceUtils"
import useCurrencyStore from "@/web/stores/currency"
import { Currency, getAllCurrencies } from "@/utils/currency"
import { FieldError } from "react-hook-form"
import { parseUnits } from "viem"

export const InputWithCurrency = ({
  amount,
  setAmount,
  id = "modal-set-price-amount",
  label,
  selectedCurrency,
  setSelectedCurrency,
  hasSuccess,
  hasError,
  onlyEth,
  split,
  lockedCurrency,
}: {
  amount: string | number
  setAmount: (amount: string | number | "") => void
  id?: string
  label?: string
  selectedCurrency: Currency
  setSelectedCurrency: (currency: Currency["symbol"]) => void
  hasSuccess?: boolean
  onlyEth?: boolean
  hasError?: FieldError | undefined
  split?: boolean
  lockedCurrency?: boolean
}): JSX.Element => {
  const { ethPrice, rarePrice } = useCurrencyStore()
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (/[^\d,.]/.test(e.target.value)) return
    let newValue = e.target.value.replace(",", ".")
    if (/[.,].*[.,]/.test(newValue)) return
    if (newValue.startsWith(".")) {
      newValue = "0" + newValue
    }
    setAmount(newValue)
  }
  const { dollars } = getUsdCurrencyObject({
    amount: parseUnits(String(amount), selectedCurrency.decimals),
    usd: selectedCurrency.symbol === "ETH" ? ethPrice : rarePrice,
    currencyType: selectedCurrency.symbol,
  })
  return (
    <div>
      <label
        htmlFor={id}
        className="mb-1 text-sm uppercase tracking-wide text-typography-grey-1"
      >
        {label}
      </label>
      <div className={cn("relative mt-1 flex w-full", split && "gap-4")}>
        <Input
          id="modal-set-price-amount"
          className={cn(
            `
              h-10 w-full rounded rounded-r-none border border-sr-border-secondary bg-sr-bg-primary pl-2
              text-sr-text-tertiary
              focus:text-sr-text-primary
            `,
            onlyEth && "rounded",
            split && "rounded"
          )}
          value={amount}
          onChange={handleChange}
          fullWidth
          hasSuccess={hasSuccess}
          hasError={hasError}
          suffix={
            onlyEth || lockedCurrency ? selectedCurrency.symbol : undefined
          }
          prefix={split ? selectedCurrency.symbol : undefined}
        />

        {!onlyEth && !lockedCurrency && (
          <Popover
            align="end"
            // className="z-10 border border-black bg-white p-3 dark:border-white dark:bg-black"
          >
            <Popover.Trigger>
              <div
                className={cn(
                  `
                    flex h-10 w-24 justify-between rounded rounded-l-none border border-l-0 border-sr-border-secondary
                    p-2
                  `,
                  split &&
                    `
                      w-40 rounded border
                      md:w-[158.25px]
                    `
                )}
              >
                <p>{selectedCurrency?.symbol}</p>
                {!onlyEth && <Icon name="arrowDown" className="my-auto" />}
              </div>
            </Popover.Trigger>
            <Popover.Content style={{ zIndex: 9999 }}>
              <ul
                className={cn(
                  "flex w-16 flex-col gap-2",
                  split && "md:w-[122px]"
                )}
              >
                {getAllCurrencies().map((currency) => (
                  <li key={currency.symbol}>
                    <button
                      onClick={() => {
                        setSelectedCurrency(currency.symbol)
                      }}
                    >
                      {currency.symbol}
                    </button>
                  </li>
                ))}
              </ul>
            </Popover.Content>
          </Popover>
        )}
      </div>
      <div className="flex justify-between">
        <div className="pt-1 text-xs text-sr-text-tertiary">{dollars}</div>
        {split && (
          <div className="w-40 pt-1 text-xs text-sr-text-tertiary">
            Bids can only be placed in this currency
          </div>
        )}
      </div>
    </div>
  )
}
